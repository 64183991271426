@import '../../assets/scss/core/variables';

.country-flag {
  height: 1em;
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.language-icon {
  font-size: 0.9em;
  cursor: pointer;
  align-items: center;

  .language-text {
    color: white;

    svg {
      margin-left: 0.2em;
    }
  }
}
