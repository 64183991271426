.contact-form-modal {
  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 1em;

      .ant-form-item-label {
        padding-bottom: 0 !important;
      }

      .ant-input,
      .ant-input-affix-wrapper,
      .ant-input-textarea {
        padding: 0.5em;
      }
    }

    .ant-form-item:last-child {
      text-align: right;
    }
  }

  .info {
    font-size: 0.8rem;
    font-style: italic;
  }
}
