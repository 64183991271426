@import './assets/scss/core/variables';
@import './antd-overwrite.scss';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Open Sans';
  font-size: 1em;
  line-height: 1.3em;

  h1 {
    font-size: 2rem !important;
    margin: 0;
    line-height: 1.3em;
    color: $defaultTextColor;
  }

  h2 {
    font-size: 1.5rem !important;
    font-weight: 600;
    margin: 0;
    line-height: 1.3em;
    color: $defaultTextColor;
  }

  p {
    font-size: 1.1rem;
    margin: 0;
    line-height: 1.3em;
    color: $defaultTextColor;
    text-align: justify;
  }

  li {
    font-size: 1.1rem;
    color: $defaultTextColor;
    list-style-type: circle;
    line-height: 1.3em !important;
  }

  @media screen and (max-width: $laptop) {
    h1 {
      font-size: 1.8rem !important;
    }

    h2 {
      font-size: 1.3rem !important;
    }

    p {
      font-size: 1.1rem;
    }

    li {
      font-size: 1.1rem;
    }
  }
  @media screen and (max-width: $md) {
    h1 {
      font-size: 1.6rem !important;
    }

    h2 {
      font-size: 1.1rem !important;
    }

    p {
      font-size: 1rem;
    }

    li {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: $xxs) {
    h1 {
      font-size: 1.4rem !important;
    }

    h2 {
      font-size: 1rem !important;
    }

    p {
      font-size: 0.8rem;
    }

    li {
      font-size: 0.8rem;
    }
  }
}

ul {
  margin: 0;
}

.hideOnDesktop {
  display: block !important;
}

.hideOnMobile {
  display: none !important;
}

@media (min-width: $md) {
  .hideOnMobile {
    display: block !important;
  }

  .hideOnDesktop {
    display: none !important;
  }
}

/*** Margin Padding ***/

.mt-0_1em {
  margin-top: 0.1em;
}

.mt-1em {
  margin-top: 1em;
}

.mb-1em {
  margin-bottom: 1em;
}

/** display **/

.d-flex {
  display: flex;
}

.d-flex-middle {
  align-items: center;
}

.d-flex-center {
  justify-content: center;
}

.d-flex-column {
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

/** Carousel **/

.carousel {
  .control-dots {
    text-align: right;
    padding-right: 1.6em !important;

    li {
      background: $primaryColorDark !important;
    }
  }

  .control-arrow {
    opacity: 1 !important;
    &:hover {
      background-color: unset !important;
    }
  }
}

.carousel.carousel-slider li.slide.selected {
  z-index: 0 !important;
}

/** section-carousel **/

.section-carousel {
  .legend {
    top: 0;
    height: 100%;
    background: unset !important;
    width: unset !important;
    left: unset !important;
    margin-left: 0 !important;
    opacity: 1 !important;
    display: flex;
    justify-content: end;
    width: 100% !important;
    color: black !important;
    padding: 2rem 10rem;
    gap: 3em;

    .img-container {
      width: 30em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1em 0;
      gap: 1em;

      img {
        max-width: 30em;
        display: block;
        width: auto;
        height: auto;
      }

      .link {
        font-size: 1.2rem;
        z-index: 9999 !important;
        cursor: pointer !important;
      }
    }

    .text-container {
      width: 75vw;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: baseline;
      gap: 1.5em;
      margin: 1em 0;
    }

    .desc-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 0.5em;

      .desc {
        text-align: justify;
      }

      .list {
        text-align: left;
      }
    }
  }

  .control-next.control-arrow {
    right: 0.5em;
  }

  .control-next.control-arrow:before {
    border-left: 16px solid $primaryColorDark;
  }

  .control-prev.control-arrow {
    left: 0.5em;
  }

  .control-prev.control-arrow:before {
    border-right: 16px solid $primaryColorDark;
  }
  @media (max-width: $xs) {
    .control-prev.control-arrow {
      left: 0em;
    }
    .control-next.control-arrow {
      right: 0em;
    }
  }
}

/** section **/

.section-content {
  padding: 2rem 10rem;

  &--small {
    padding: 2em 25em !important;
  }

  &--flex {
    display: flex;
    gap: 3em;

    .left {
      width: 25vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1em;

      img {
        max-width: 20em;
        display: block;
        width: auto;
        height: auto;
      }
    }

    .right {
      width: 75vw;
      display: flex;
      flex-direction: column;
      gap: 1em;

      .desc-container {
        display: flex;
        flex-direction: column;
        gap: 1em;
        text-align: justify;

        .list {
          li:not(:last-child) {
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }
}

@media (max-width: $xxl) {
  .section-content {
    padding: 2rem 7rem;
  }

  .section-carousel .legend {
    padding: 2rem 7rem;
  }

  .section-content {
    &--small {
      padding: 2em 9em !important;
    }
  }
}

@media (max-width: $xl) {
  .section-content {
    .left {
      img {
        width: 15em;
      }
    }

    &--small {
      padding: 2em 20em !important;
    }
  }
}

@media (max-width: $laptop) {
  .section-carousel .legend .img-container img {
    max-width: 22em !important;
  }

  .section-content {
    &--small {
      padding: 2em 9em !important;
    }
  }
}

@media (max-width: $lg) {
  .section-content--small {
    padding: 1em 3em !important;
  }
}
@media (max-width: $md) {
  .section-content {
    padding: 1rem 3.5rem;
  }
  .section-carousel .legend {
    .desc-container {
      width: 100%;
    }
  }
}
@media (max-width: $sm) {
  .section-carousel .legend {
    gap: 0;
  }
  .section-content--small {
    padding: 0.5em 1.5em !important;
  }
}
@media (max-width: $xs) {
}
