@import '../../assets/scss/core/variables';

.footer {
  display: flex;
  flex-direction: column;
  background-color: $footerColor;
  justify-content: space-between;
  height: 30em !important;

  .nav {
    display: flex;
    justify-content: end;
    align-items: baseline;
    height: 2em;
    padding-right: 1em;
    background-color: $footerColor;
    a {
      color: white !important;
      text-decoration: unset;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
    li {
      list-style: none;
    }

    .white {
      color: white !important;
    }
  }

  &-main-container {
    background-color: $footerColor;
    padding: 2em;
    .logo-container {
      display: flex;
      justify-content: center;
      margin-bottom: 2em;

      .logo {
        width: 25em;
      }
    }

    .office-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .office {
        display: flex;
        gap: 1em;

        .title {
          margin-bottom: 0.3em;
          text-decoration: underline;
        }
        .img-container {
          img {
            width: 20em;
          }
        }

        .text-container {
          width: 25em;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;

          p {
            color: white !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: $xl) {
    .logo-container {
      .logo {
        width: 20em;
      }
    }

    .office {
      .img-container {
        img {
          width: 13em;
        }
      }

      .text-container {
        width: 20em !important;
      }
    }
  }
  @media screen and (max-width: $lg) {
    .office-container {
      flex-direction: column;
      gap: 1em;
      .office {
        justify-content: space-evenly;
        .text-container {
          width: 20em !important;
        }
      }
    }
  }
  @media screen and (max-width: $md) {
    .office-container {
      .office {
        .text-container {
          width: 10em !important;
        }
      }
    }
  }
  @media screen and (max-width: $xxs) {
    .office-container {
      .office {
        flex-direction: column;
        width: 100%;
        align-items: center;
        .text-container {
          width: 70% !important;
          p {
            text-align: center;
          }
        }
        .img-container {
          img {
            width: 11em;
          }
        }
      }
    }
  }
}
