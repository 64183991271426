@import '../../assets/scss/core/variables';

.main-banner {
  height: 25em;
  background-size: cover;
  background-position: top;

  &-children {
    height: 25em;

    .card {
      height: 100%;
      opacity: 0.7 !important;
      display: flex;
      justify-content: end;
      align-items: center;

      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 2em;
        background-color: $primaryColorDark;
        padding: 2em 3.5em;
        width: 40%;
        margin: 2em;
        box-shadow: 5px 5px 20px;

        .title {
          color: white;
        }

        .desc {
          font-size: 1.5em;
          font-weight: 600;
          color: white;
          text-align: justify;
        }
      }
    }
  }

  @media screen and (max-width: $lg) {
    .card {
      justify-content: center;
      width: 100%;
      height: 100%;

      .text-container {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $md) {
    height: 22em;
    &-children {
      height: 20em !important;
      .card {
        justify-content: space-evenly;
        .text-container {
          padding: 0.5em 1em;
          .desc {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: $sm) {
    height: 20em;

    .main-banner-children {
      height: auto;
    }
  }
  @media screen and (max-width: $xxs) {
    h1 {
      font-size: 1.2em !important;
    }
    .card {
      justify-content: space-evenly;
      .text-container {
        .desc {
          font-size: 1rem;
        }
      }
    }
    height: 20em;
  }
  @media screen and (max-width: $xxxs) {
    height: 22em;
  }
}
