@import '../../assets/scss/core/variables';

.navbar {
  list-style: none;
  display: flex;
  align-items: center;

  .active {
    font-weight: bold;
  }

  li {
    margin-right: 0.3em;
    font-size: 1.2em;
    color: white;
    list-style-type: none !important;

    &:last-child {
      margin-right: 0;
    }
    a {
      text-decoration: none;
      color: white;
      cursor: pointer;

      &:hover {
        position: relative;
        text-decoration: solid underline white 2px;
        text-underline-offset: 4px;
      }
    }
  }
  @media (max-width: $md) {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    padding: 0;
    .active {
      font-weight: bold;
    }
    li {
      a {
        font-size: 1.4em;
      }
    }
  }
}
