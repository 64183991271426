@import '../../assets/scss/core/variables';

.company-details {
  .ant-collapse-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-collapse-content-box {
    margin-left: 2em;
  }

  /** mosaic common */

  .mosaic-title {
    font-weight: bold;
    margin-bottom: 0.5em;
    text-align: center;
  }

  .mosaic-container {
    display: flex;
    gap: 1em;

    .mosaic {
      display: flex;
      flex-direction: column;
      width: 33%;
      gap: 0.5em;

      .desc-container {
        .title {
          text-align: center;
          font-weight: bold;
        }

        .content {
          text-align: center;
        }
      }
    }
  }
  @media (max-width: $lg) {
    .mosaic-container {
      display: flex;
      gap: 1em;
      .mosaic {
        align-items: center;
        width: 90%;
        img {
          width: 70%;
        }
      }
    }
  }
  @media (max-width: $md) {
    .mosaic-container {
      flex-direction: column;
      align-items: center;
    }
    .ant-collapse-content-box {
      margin-left: 0;
    }
  }
}
