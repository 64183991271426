@import '../../assets/scss/core/variables';

.secure-data-flow {
  .strengths-container {
    display: flex;
    margin-top: 2em;

    .strengths-text-container {
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 50%;
    }

    .strengths-img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      .strengths-img {
        width: 25em;
      }
    }
  }
  .badge {
    width: 10em !important;
  }

  @media screen and (max-width: $xl) {
    .strengths-img {
      width: 18em !important;
    }
  }

  @media screen and (max-width: $lg) {
    .strengths-container {
      flex-direction: column;

      .strengths-text-container {
        width: auto;
      }
      .strengths-img-container {
        width: auto;
      }
    }
  }
  @media screen and (max-width: $md) {
    img {
      margin-top: 1em;
    }
  }
  @media screen and (max-width: $sm) {
  }
}
@media screen and (max-width: $lg) {
  .section-content {
    flex-direction: column;
    align-items: center;
  }
  .left {
    .parcours {
      width: 12em !important;
    }
    .badge {
      width: 8em !important;
    }
  }
}
