@import '../../assets/scss/core/variables';

.header {
  background-color: $primaryColor;
  height: 12em;

  .navigation {
    position: absolute;
    right: 0;
    padding: 0.5em 1em;
  }

  .menu-burger {
    color: #92b0c8;
    position: absolute;
    right: 0;
    padding: 0.5em;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .logo {
      width: 40em;
      z-index: 999;
    }
  }

  .custom-drawer {
    background-color: $primaryColor;
  }

  @media (max-width: $laptop) {
    .logo-container {
      .logo {
        width: 35em !important;
      }
    }
  }
  @media (max-width: $sm) {
    height: 10em;
    .logo-container {
      .logo {
        width: 74% !important;
        padding: 0 2em;
      }
    }
  }
}
.close-icon-menu {
  color: white;
}
