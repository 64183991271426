@import '../../assets/scss/core/variables';

.list-cards {
  height: 40em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 1em;
  align-items: center;
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 2em 24em !important;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: none;

    &-visible {
      display: flex !important;
    }
  }

  .card-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      width: 20em;
      height: 22em;
      box-shadow: 4px 4px 8px $primaryColorDark;

      &:hover {
        scale: 1.05;
      }
    }
  }

  .expanded-card-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    max-height: 70em;
    max-width: 70%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 2em 4em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    box-shadow: 0 0 10px $primaryColorDark;

    .close-icon {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      cursor: pointer;

      &:hover {
        color: grey !important;
      }
    }

    .expanded-card {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;

      &-content {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 3em;

        .img-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 30vw;
          gap: 1em;

          img {
            display: block;
            width: auto;
            height: auto;
            max-width: 25em;
          }
        }

        .desc-container {
          width: 70vw;
          display: flex;
          flex-direction: column;
          gap: 1.5em;
        }
      }
    }
  }
  @media (max-width: $xxxl) {
    padding: 2em 15em !important;
    .card {
      height: 22em !important;
    }
  }

  @media (max-width: $xl) {
    padding: 2em 11em !important;
  }

  @media (max-width: $laptop) {
    padding: 2em 8em !important;

    .card {
      height: 22em;
      width: 20em;
    }
  }

  @media (max-width: $l) {
    padding: 2em 10em !important;

    .card {
      width: 17em !important;
      height: 20em !important;

      .img-container {
        width: 12em;
      }
    }
  }

  @media screen and (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    padding: 2em !important;
  }

  @media screen and (max-width: $md) {
    .card-container {
      .card {
        width: 14em !important;
        height: 16em !important;
        .ant-card-body {
          padding: 20px 2px 20px 2px;
          .ant-card-meta-title {
            font-size: 1em !important;
          }
        }
      }
    }
    .expanded-card-container {
      .expanded-card {
        &-content {
          gap: 1em !important;
        }
        .expanded-card-content {
          img {
            width: 120% !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
    padding: 1em !important;
    .card-container {
      .card {
        width: 10em !important;
        height: 12em !important;
        .ant-card-body {
          padding: 20px 1px 20px 1px;
          .ant-card-meta-title {
            font-size: 1em !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: $xxxs) {
    .card-container {
      .card {
        width: 9em !important;
        height: 11em !important;
        .ant-card-body {
          .ant-card-meta-title {
            font-size: 0.9em !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .list-cards {
    .expanded-card-container {
      .expanded-card {
        .expanded-card-content {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
@media screen and (max-width: $xs) {
  .list-cards {
    .expanded-card-container {
      padding: 1em 2em;
      .expanded-card {
        .expanded-card-content {
          img {
            width: 150%;
          }
        }
      }
    }
  }
}
