$primaryColor: #033f61;
$primaryColorDark: #00304e;
$textColor: #3b3b3b;
$navTextColor: #92b0c8;
$lightgrey: #e0e0e0;
$footerColor: #3f3f3f;
$defaultTextColor: #3d3c3c;

$xxxs: 345px;
$xxs: 440px;
$xs: 500px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$l: 1200px;
$xl: 1400px;
$xxl: 1600px;
$xxxl: 1800px;
$laptop: 1280px;
