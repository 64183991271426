@import '../../assets/scss/core/variables';

.solution-carousel {
  &-children {
    height: 21em;
    .legend {
      padding: 2rem 7rem !important;
    }
    .auxasuite {
      margin-bottom: 4em;
    }
  }

  @media screen and (max-width: $lg) {
    &-children {
      height: 40em;
    }
    .legend {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start !important;
      .text-container {
        margin: 3em 0 0 0 !important;
      }
    }
  }
  @media screen and (max-width: $sm) {
    &-children {
      height: 43em;
    }
    .legend {
      padding: 0 !important;
      .img-container {
        gap: 0;
        margin: 3em 0 0 0 !important;
        width: 18em !important;
      }
    }
  }
  @media screen and (max-width: $xxs) {
    &-children {
      height: 50em;
    }
    .legend {
      .img-container {
        width: 16em !important;
      }
    }
  }
  @media screen and (max-width: $xxxs) {
    &-children {
      height: 50em;
    }
    .legend {
      .img-container {
        width: 14em !important;
      }
    }
  }
}
