@import '../../assets/scss/core/variables';

.home {
  &-banner {
    height: 50vh;
    background-image: url('../../../public/images/banner.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;

    .title {
      color: white;
      font-size: 5em;
      font-weight: 600;
      margin: 0;
      letter-spacing: 0.1em;
    }

    .desc {
      color: white;
      font-size: 1.95em;
      margin: 0;
    }
  }

  &-footer {
    height: 35vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 0 30em;

    .title {
      font-size: 1.7em;
      text-align: center;
      margin: 0;
      padding: 1em 0;
      color: $textColor;
    }

    .wrapper {
      display: flex;
      justify-content: center;
      gap: 3em;
    }
  }

  @media (max-width: $xxl) {
    &-banner {
      .title {
        font-size: 4em !important;
      }
    }
    &-footer {
      padding: 0 15em;
    }
  }

  @media (max-width: $xl) {
    &-banner {
      .title {
        font-size: 3em !important;
      }
    }
    &-footer {
      padding: 0 10em;
    }
  }

  @media (max-width: $lg) {
    &-banner {
      .title {
        font-size: 2.5em !important;
      }

      .desc {
        font-size: 1.5em;
      }
    }

    &-footer {
      padding: 0 5em;

      .title {
        font-size: 1.4em !important;
      }
    }
  }

  @media (max-width: $lg) {
    &-footer {
      padding: 0 1em;
    }
  }

  @media (max-width: $sm) {
    &-banner {
      .title {
        font-size: 1.7em !important;
      }

      .desc {
        font-size: 1.2em !important;
      }
    }

    &-footer {
      .wrapper {
        flex-direction: column;
        align-items: center;
        gap: 0;
      }
    }
  }
}
