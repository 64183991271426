@import '../../assets/scss/core/variables';

.security-scalability-carousel {
  &-children {
    height: 18em;
    .legend {
      padding: 2rem 7rem !important;
    }
    .shield {
      width: 13em !important;
    }
  }

  @media (max-width: $xxl) {
    &-children {
      height: 19em;

      .shield {
        width: 16em !important;
      }
    }
  }

  @media (max-width: $xl) {
    &-children {
      height: 20em;

      .shield {
        width: 16em !important;
      }
    }
  }

  @media (max-width: $laptop) {
    &-children {
      height: 20em;

      .shield {
        width: 16em !important;
      }
    }
  }
  @media screen and (max-width: $lg) {
    &-children {
      .legend {
        padding: 2rem 7rem !important;
      }
      height: 40em;
    }
    .legend {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start !important;
    }
  }
  @media screen and (max-width: $md) {
    &-children {
      .shield {
        width: 10em !important;
      }
    }
  }
  @media screen and (max-width: $xxs) {
    &-children {
      height: 42em;
      .shield {
        width: 8em !important;
      }
    }
  }
  @media screen and (max-width: $xxxs) {
    &-children {
      height: 47em;
    }
    .legend {
      .img-container {
        width: 7em !important;
      }
    }
  }
}
