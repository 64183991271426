@import '../../assets/scss/core/variables';

.partnership-carousel {
  &-children {
    height: 28em;

    .legend {
      padding: 2rem 7rem !important;
    }

    .img-container {
      padding: 0 0 1.5em 0;
    }

    .text-container {
      .main-title {
        padding: 0 0 0.4em 0;
      }
    }

    .site-container {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1em 0;

      img {
        width: 30em !important;
      }
    }

    .obese-container {
      display: flex;
      justify-content: center;
      width: 100%;

      .obese-img {
        width: 70em !important;
      }
    }

    .citation-saclay {
      line-height: 1.5em;
    }

    .citation {
      display: flex;
      justify-content: center;
      width: 100%;
      font-weight: bold;
    }

    .learn-more {
      text-align: right;
      font-size: 1rem !important;
      width: 100%;
      margin-top: 0.5em;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: $xxl) {
    &-children {
      height: 30em;

      .site-container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1em 0;

        img {
          width: 30em !important;
        }
      }

      .obese-container {
        display: flex;
        justify-content: center;
        width: 100%;

        .obese-img {
          width: 50em !important;
        }
      }
    }
  }
  @media screen and (max-width: $lg) {
    &-children {
      height: 40em;
      .legend {
        padding: 0 !important;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start !important;
        gap: 1em;
        .img-container {
          flex-direction: row !important;
          img {
            width: 12em !important;
          }
        }
        .text-container {
          margin: 0 !important;
          gap: 0 !important;
        }
      }
    }
  }
  @media screen and (max-width: $md) {
    &-children .obese-container .obese-img {
      width: 100% !important;
    }
    .legend {
      padding: 1em 3em !important;
      gap: 0 !important;
      .img-container {
        margin: 0 !important;
      }
      .text-container {
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: $sm) {
    &-children {
      height: 45em;
      .legend {
        padding: 0;
        .site-container {
          img {
            width: 100% !important;
          }
        }
        .text-container {
          i {
            p {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $xxs) {
    &-children {
      height: 50em;
      .legend {
        .text-container {
          i {
            p {
              font-size: 1em;
            }
          }
        }
      }
      .learn-more {
        margin: 0;
        font-size: 1em !important;
      }
    }
  }

  @media screen and (max-width: 368px) {
    &-children {
      height: 52em;
    }
  }

  @media screen and (max-width: $xxxs) {
    &-children {
      height: 52em;
    }
    .legend {
      .img-container {
        width: 14em !important;
      }
      .text-container {
        .main-title {
          font-size: 1.5em !important;
        }
        .sub-title {
          font-size: 1em !important;
        }
        p {
          font-size: 0.95em !important;
        }
      }
    }
  }
}
