@import '../../assets/scss/core/variables';

.proposition {
  height: 20em;
  background-color: $primaryColorDark;

  .title {
    text-align: center;
    color: white;
    margin-bottom: 1em;
  }

  .badges-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
    color: white;

    .badge {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 0.5em;

      h2 {
        color: white;
        text-align: center;
      }
    }

    .connection {
      margin-bottom: 2em;
    }
  }

  @media screen and (max-width: $xl) {
    padding: 1em 2em !important;
    .badges-container {
      .badge {
        img {
          width: 10em;
        }
      }
    }
  }
  @media screen and (max-width: $l) {
    .badges-container {
      gap: 1em;
    }
  }
  @media screen and (max-width: $lg) {
    .badges-container {
      gap: 0;
    }
  }
  @media screen and (max-width: $md) {
    height: auto;
    .badges-container {
      flex-direction: column;
      justify-content: space-around;
      gap: 1em;
      .connection {
        margin: 0;
      }
    }
  }
  @media screen and (max-width: $sm) {
    padding: 1em 2em !important;
    .badges-container {
      .badge {
        img {
          width: 8em;
        }
      }
    }
  }
  @media screen and (max-width: $xs) {
    padding: 1em 1em !important;
    .badges-container {
      .badge {
        img {
          width: 7em;
        }
      }
    }
  }
}
