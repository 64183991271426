.punchline {
  height: 20vh;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: center;
  gap: 1em;
  background-size: cover;

  .punchline-title {
    color: white;
  }

  .punchline-desc {
    color: white;
  }
}
