@import '../../assets/scss/core/variables';

.commitments {
  .more-info {
    font-style: italic;
    margin-top: 2em;
    font-weight: bold;
    font-size: 1.1rem;
  }
  @media (max-width: $lg) {
    .mosaic-container {
      .mosaic {
        align-items: center;
      }
    }
    img {
      width: 70%;
    }
  }
  @media (max-width: $sm) {
    .mosaic-container {
      flex-direction: column;
      align-items: center;
      .mosaic {
        align-items: center;
      }
    }
    img {
      width: 90%;
    }
  }
  @media (max-width: $xs) {
    .more-info {
      margin-top: 1em;
      font-size: 1em !important;
    }
  }
}
