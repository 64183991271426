.ant-menu-item-active {
  color: white !important;

  &::after {
    border-bottom-color: white !important;
  }
}

.ant-card-meta-title {
  text-align: center;
  font-size: 1.5em !important;
}

.ant-modal-title {
  font-size: 1.5em !important;
}

.ant-form-item-explain-error {
  text-align: left;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    content: '';
    margin-inline-end: 0;
  }

  &::after {
    display: inline-block;
    margin-inline-start: 0rem;
    color: red;
    font-size: 0.7rem;
    padding-bottom: 0.4rem;
    line-height: 1;
    content: '*';
    visibility: inherit !important;
  }
}
